export default function GraphConfigurationBuilder(options, data, time){
    const stateTemperature = {
                                rangeSelector: {
                                    selected: 1
                                },
                                title: {
                                    text: options.name,
                                },
                                xAxis: {
                                    type: 'datetime',
                                    categories: time,
                                    labels: {
                                    format: '{value:%Y-%m-%d}'
                                    } 
                                },  
                                series: [{
                                    name: options.name,
                                    data: data,
                                    tooltip: {
                                        valueDecimals: 2
                                    }
                                }]
                            }

    return stateTemperature;
}