export  const wifiGraphicConfiguration = {
    color: '#020B2C',
    name: 'Wifi'
}

export  const batGraphicConfiguration = {
    color: '#008A4E',
    name: 'Bateria'
}

export const temperatureGraphicConfiguration = {
    color: '#014B8C',
    name: 'Temperatura'
}

export const noiseGraphicConfiguration = {
    color: '#F84128',
    name: 'Ruído'
}

export const gryGraphicConfiguration = {
    color: '#016B8C',
    name: 'Giroscópio'
}

export const accGraphicConfiguration = {
    color: '#008B',
    name: 'Acelerômetro'
}

export const anomalyGraphicConfiguration = {
    color: '#008B',
    name: 'Anomalia'
}

export const timeOperationGraphicConfiguration = {
    color: '#008B',
    name: 'Tempo de Operação'
}