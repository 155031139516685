import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './graphs.scss';

import Top from './../components/top/Top'
import PeriodSelector from '../components/time-selector/PeriodSelector'
import ChartPanel from '../components/chat-panel/ChartPanel'

import logo_ISI from '../../assets/logo_ISI.png'
import logo_ABDI from '../../assets/logo_abdi.png'

import GraphConfigurationBuilder from '../components/configuration/GraphConfigurationBuilder'
import {
  wifiGraphicConfiguration, batGraphicConfiguration, temperatureGraphicConfiguration, anomalyGraphicConfiguration, noiseGraphicConfiguration,
  timeOperationGraphicConfiguration, gryGraphicConfiguration, accGraphicConfiguration
} from '../components/configuration/TemplateConfigurationGraphs'
import AnomalyTable from '../components/anomaly-table/AnomalyTable';
import { Button } from '@mui/material';

export default function Home(props) {
  const { idDevice } = useParams()
  const urlAddressAPI = 'https://api.abdi.isitics.com'
  // const urlAddressAPI = 'http://localhost:3100'

  const [temperatures, setTemperatures] = useState('');
  const [dateTemperatures, setDateTemperatures] = useState('');

  const [noiseLevel, setNoiseLevel] = useState('');
  const [dateNoiseLevel, setDateNoiseLevel] = useState('');

  const [wifiQuality, setWifiQuality] = useState('');
  const [dateWifiQuality, setDateWifiQuality] = useState('');

  const [batteryData, setBatteryData] = useState('');
  const [dateBatteryData, setDateBatteryData] = useState('');

  const [gyroscopeData, setGyroscopeData] = useState('');
  const [dateGyroscopeData, setDateGyroscopeData] = useState('');

  const [accelerometerData, setAccelerometerData] = useState('');
  const [dateAccelerometerData, setDateAccelerometerData] = useState('');

  const [anomalyData, setAnomalyData] = useState('');
  const [anomalyRows, setAnomalyRows] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [weekCounter, setWeekCounter] = useState(0);
  const [dateAnomalyData, setDateAnomalyData] = useState('');
  const [numberOfAnomalyEvents, setNumberOfAnomalyEvents] = useState('');
  const [percentOfAnomalyEvents, setPercentOfAnomalyEvents] = useState('');
  const [customTime, SetCustomTime] = useState(false);
  const [startDate, SetStartDate] = useState('');
  const [endDate, SetEndDate] = useState('');

  const [operationData, setOperationData] = useState('');
  const [dateOperation, setDateOperation] = useState('');
  const [timeOfOperation, setTimeOfOperation] = useState('');


  useEffect(() => {
    selectPeriod('ONE_HOUR');
    findAnomalyperWeekInitial()
    document.title = idDevice;
  }, []);


  function findAnomalyCodeByPeriod(period) {
    const urlAcc = `${urlAddressAPI}/findAnomalyInformation/${idDevice}/${period}`
    fetch(urlAcc).then(response => response.json())
      .then(dataAnomaly => {
        const anomalyDataArray = []
        const dateAnomalyDataArray = []

        dataAnomaly.data.anomaly.forEach(item => {
          if (item.anomaly_code != null && item.time != null) {
            anomalyDataArray.push(item.anomaly_code)
            dateAnomalyDataArray.push(formatDate(item.time))
          }
        })
        setNumberOfAnomalyEvents(dataAnomaly.data.numberOfAnomalyEvents)
        setPercentOfAnomalyEvents(dataAnomaly.data.anomalyPercentual.toFixed(2) + '%')
        setAnomalyData(anomalyDataArray)
        setDateAnomalyData(dateAnomalyDataArray)

      })
      .catch(err => console.log(err))
  }

  function finAccelerometerByPeriod(period) {
    const urlAccelerometer = `${urlAddressAPI}/findOne/acc_level/${idDevice}/${period}`

    fetch(urlAccelerometer).then(response => response.json())
      .then(dataAccelerometer => {
        const accelerometerDataArray = []
        const dateAccelerometerDataArray = []

        dataAccelerometer.data.forEach(item => {
          if (item.acc_level != null) {
            accelerometerDataArray.push(item.acc_level)
            dateAccelerometerDataArray.push(formatDate(item.time))
          }
        })

        setAccelerometerData(accelerometerDataArray);
        setDateAccelerometerData(dateAccelerometerDataArray);
      }).catch(err => console.log(err))
  }

  function findBatteryByPeriod(period) {
    const urlBattery = `${urlAddressAPI}/findOne/v_bat/${idDevice}/${period}`


    fetch(urlBattery).then(response => response.json())
      .then(dataBattery => {
        const batteryData = []
        const dateBatteryData = []

        dataBattery.data.forEach(item => {
          if (item.v_bat != null) {
            batteryData.push(item.v_bat);
            dateBatteryData.push(formatDate(item.time))
          }
        })

        setBatteryData(batteryData)
        setDateBatteryData(dateBatteryData)
      }).catch(err => console.log(err))
  }

  function findGyroscopeDataByPeriod(period) {
    const urlGyroscope = `${urlAddressAPI}/findOne/gyr_level/${idDevice}/${period}`

    fetch(urlGyroscope).then(response => response.json())
      .then(dataGyroscope => {
        const gyroscopeDataArray = []
        const dateGyroscopeDataArray = []

        dataGyroscope.data.forEach(item => {
          if (item.gyr_level != null) {
            gyroscopeDataArray.push(item.gyr_level)
            dateGyroscopeDataArray.push(formatDate(item.time))
          }
        })

        setGyroscopeData(gyroscopeDataArray)
        setDateGyroscopeData(dateGyroscopeDataArray)
      }).catch(err => console.log(err))
  }

  function findTemperatureByPeriod(period) {
    const urlTemperature = `${urlAddressAPI}/findOne/temperature/${idDevice}/${period}`

    fetch(urlTemperature).then(response => response.json())
      .then(temperatureData => {
        const temperatures = []
        const dateReadingTemperature = []
        temperatureData.data.forEach(item => {
          if (item.temperature !== null) {
            temperatures.push(item.temperature);
            dateReadingTemperature.push(formatDate(item.time))
          }
        })

        setTemperatures(temperatures)
        setDateTemperatures(dateReadingTemperature)
      }).catch(err => console.log(err))
  }

  function findNoiseByPeriod(period) {
    const urlNoise = `${urlAddressAPI}/findOne/noise_level/${idDevice}/${period}`

    fetch(urlNoise).then(response => response.json())
      .then(noiseData => {
        const noiseLevel = []
        const dateNoiseLevel = []

        noiseData.data.forEach(item => {
          if (item.noise_level != null) {
            noiseLevel.push(item.noise_level);
            dateNoiseLevel.push(formatDate(item.time))
          }
        })

        setNoiseLevel(noiseLevel)
        setDateNoiseLevel(dateNoiseLevel)
      }).catch(err => console.log(err))
  }

  function findWifiQualityByPeriod(period) {
    const url = `${urlAddressAPI}/findOne/wifi_quality/${idDevice}/${period}`

    fetch(url).then(response => response.json())
      .then(wifiQualityData => {
        const wifiQuality = []
        const dateWifiQuality = []

        wifiQualityData.data.forEach(item => {
          if (item.wifi_quality != null) {
            wifiQuality.push(item.wifi_quality);
            dateWifiQuality.push(formatDate(item.time))
          }
        })
        setWifiQuality(wifiQuality)
        setDateWifiQuality(dateWifiQuality)
      })
      .catch(err => console.log(err))
  }

  function findOperatingTimeByPeriod(period) {
    const url = `${urlAddressAPI}/operating/${idDevice}/${period}`
    fetch(url).then(response => response.json())
      .then(operatingData => {
        const operatingTimeData = []
        const dateOperatingTime = []

        operatingData.data.operationsRecords.forEach(item => {
          if (item.operation_code != null) {
            operatingTimeData.push(item.operation_code);
            dateOperatingTime.push(formatDate(item.time))
          }
        })

        setTimeOfOperation(operatingData.data.operatingTime)
        setOperationData(operatingTimeData)
        setDateOperation(dateOperatingTime)
      })
      .catch(err => console.log(err))
  }

  function formatDate(date) {
    var dateComponent = date.substring(0, 10).split('-')
    var timeComponent = date.substring(11, 19).split(':')
    return dateComponent[2] + "/" + dateComponent[1] + "/" + dateComponent[0] + " - " + timeComponent[0] + ":" + timeComponent[1]
  }



  const selectPeriod = (period) => {
    if (period !== "CUSTOM") {
      SetCustomTime(false)
      findWifiQualityByPeriod(period)
      findNoiseByPeriod(period)
      findGyroscopeDataByPeriod(period)
      findTemperatureByPeriod(period)
      findBatteryByPeriod(period)
      finAccelerometerByPeriod(period)
      findAnomalyCodeByPeriod(period)
      findOperatingTimeByPeriod(period)
    }
    else {
      SetCustomTime(true)
    }

  }

  function createData(id, dateI, dateF, anomaly, percent, operating) {
    return { id, dateI, dateF, anomaly, percent, operating };
  }

  function findAnomalyperWeek() {
    setTableLoading(true);
    let d = new Date()
    let startDate = formatDateAnomaly(new Date(d.getFullYear(), d.getMonth(), d.getDate() - (6 + (7 * weekCounter))));
    let endDate = formatDateAnomaly(new Date(d.getFullYear(), d.getMonth(), d.getDate() - (7 * weekCounter)));
    let rows = anomalyRows;

    let url = `${urlAddressAPI}/findAnomalyInformation/${idDevice}/${startDate}/${endDate}`
    fetch(url).then(response => response.json())
      .then(data => {

        rows.push(createData(weekCounter + 1, startDate, endDate,
          data.data.numberOfAnomalyEvents ? data.data.numberOfAnomalyEvents : 0,
          data.data.anomalyPercentual ? data.data.anomalyPercentual.toFixed(2) : 0,
          data.data.operatingTime ? data.data.operatingTime + ' segundos' : 0 + ' segundos'))

        setAnomalyRows(rows);
        setWeekCounter(weekCounter + 1);
        setTableLoading(false);

      })
      .catch(err => console.log(err))


  }

  function formatDateAnomaly(date) {
    return (date.getFullYear() + '-' +
      ((date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)) + '-' +
      (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()))
  }

  async function findAnomalyperWeekInitial() {
    let d = new Date()
    let rows = [];

    for (let i = 0; i < 4; i++) {
      let startDate = formatDateAnomaly(new Date(d.getFullYear(), d.getMonth(), d.getDate() - (6 + (7 * i))));
      let endDate = formatDateAnomaly(new Date(d.getFullYear(), d.getMonth(), d.getDate() - (7 * i)));

      let url = `${urlAddressAPI}/findAnomalyInformation/${idDevice}/${startDate}/${endDate}`
      await fetch(url).then(response => response.json())
        .then(data => {

          rows.push(createData(i + 1, startDate, endDate,
            data.data.numberOfAnomalyEvents ? data.data.numberOfAnomalyEvents : 0,
            data.data.anomalyPercentual ? data.data.anomalyPercentual.toFixed(2)  : 0,
            data.data.operatingTime ? data.data.operatingTime + ' segundos' : 0 + ' segundos'))
          if (i === 3) {
            setAnomalyRows(rows.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0)));
            setWeekCounter(4)
          }
        })
        .catch(err => console.log(err))

    }




  }

  function getMaxDate() {
    let d = new Date()
    let date = new Date(d.getFullYear(), d.getMonth(), d.getDate())
    return (date.toISOString().split("T")[0])
  }

  function getMinDate() {
    if (startDate === "") {
      return new Date()
    }
    else {
      let d = new Date(startDate)
      let date = new Date(d.getFullYear(), d.getMonth(), d.getDate() + 2)
      return (date.toISOString().split("T")[0])
    }

  }

  function graphAnomalyperWeek() {
    if (anomalyRows.length > 0) {

      let data = [];

      for (let i = anomalyRows.length; i > 0; i--) {
        let name = anomalyRows[i - 1].dateI.replace("-", "/") + " - " + anomalyRows[i - 1].dateF.replace("-", "/")
        let value = Number(anomalyRows[i - 1].percent);
        data.push([name, value])
      }


      let options = {
        chart: {
          type: 'column'
        },
        title: {
          text: 'Porcentagem de anomalias por Semana'
        },
        subtitle: {
          enabled: false
        },
        xAxis: {
          type: 'category',
          labels: {
            rotation: -45,
            style: {
              fontSize: '13px',
              fontFamily: 'Verdana, sans-serif'
            }
          }
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Anomalias'
          },
          labels: {
            format: '{text}%'
          },
        },
        legend: {
          enabled: false
        },
        tooltip: {
          pointFormat: 'porcentagem: <b>{point.y}%</b>'
        },
        series: [{
          name: 'Anomalias',
          data: data,
          dataLabels: {
            enabled: true,
            color: '#FFFFFF',
            align: 'center',
            style: {
              fontSize: '13px',
              fontFamily: 'Verdana, sans-serif'
            },
          }
        }],
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              format: '{y}%'
            }
          }
        },
      }
      return options
    }
  }

  function getCustomPeriodData() {
    getCustomData('gyr_level', setGyroscopeData, setDateGyroscopeData)
    getCustomData('v_bat', setBatteryData, setDateBatteryData)
    getCustomData('acc_level', setAccelerometerData, setDateAccelerometerData)
    getCustomData('temperature', setTemperatures, setDateTemperatures)
    getCustomData('noise_level', setNoiseLevel, setDateNoiseLevel)
    getCustomData('wifi_quality', setWifiQuality, setDateWifiQuality)
    findAnomalyCodeByCustomPeriod()
    findOperatingTimeByCustomPeriod()
  }

  function getCustomData(type, setType, setData) {
    const url = `${urlAddressAPI}/findAll/${type}/${idDevice}/${startDate}/${endDate}`

    fetch(url).then(response => response.json())
      .then(Data => {
        const valueArray = [];
        const timeArray = [];

        Data.data.forEach(item => {
          if (item[type] != null) {
            valueArray.push(item[type]);
            timeArray.push(formatDate(item.time))
          }
        });

        setType(valueArray);
        setData(timeArray);
      })
      .catch(err => console.log(err))
  }

  function findOperatingTimeByCustomPeriod() {
    const url = `${urlAddressAPI}/operating/${idDevice}/${startDate}/${endDate}`
    fetch(url).then(response => response.json())
      .then(operatingData => {
        const operatingTimeData = []
        const dateOperatingTime = []

        operatingData.data.operationsRecords.forEach(item => {
          if (item.operation_code != null) {
            operatingTimeData.push(item.operation_code);
            dateOperatingTime.push(formatDate(item.time))
          }
        })

        setTimeOfOperation(operatingData.data.operatingTime)
        setOperationData(operatingTimeData)
        setDateOperation(dateOperatingTime)
      })
      .catch(err => console.log(err))
  }

  function findAnomalyCodeByCustomPeriod() {
    const urlAcc = `${urlAddressAPI}/findAnomalyInformation/${idDevice}/${startDate}/${endDate}`
    fetch(urlAcc).then(response => response.json())
      .then(dataAnomaly => {
        const anomalyDataArray = []
        const dateAnomalyDataArray = []

        dataAnomaly.data.anomaly.forEach(item => {
          if (item.anomaly_code != null && item.time != null) {
            anomalyDataArray.push(item.anomaly_code)
            dateAnomalyDataArray.push(formatDate(item.time))
          }
        })
        setNumberOfAnomalyEvents(dataAnomaly.data.numberOfAnomalyEvents)
        setPercentOfAnomalyEvents(dataAnomaly.data.anomalyPercentual.toFixed(2) + '%')
        setAnomalyData(anomalyDataArray)
        setDateAnomalyData(dateAnomalyDataArray)

      })
      .catch(err => console.log(err))
  }


  return (
    <div className='container-xxl'>


      <div className='logo-div'>
        <img src={logo_ABDI} alt='logo ABDI' />
        <img src={logo_ISI} alt='logo ISI' />
      </div>

      <Top titlePage={idDevice} />
      <PeriodSelector selectPeriodOfSearch={selectPeriod} />

      {customTime && <div className="custom-date-div">
        <div className='date-picker'>
          <label> Data Inicial</label>
          <input type='date' max={getMaxDate()} value={startDate} onChange={(e) => SetStartDate(e.target.value)} />
        </div>

        <div className='date-picker'>
          <label> Data Final</label>
          <input type='date' max={getMaxDate()} value={endDate} min={getMinDate()} disabled={startDate === ""} onChange={(e) => SetEndDate(e.target.value)} />
        </div>
        <Button sx={{ margin: '30px' }} variant="contained" onClick={() => getCustomPeriodData()}>Obter Dados</Button>
      </div>}

      <div className="row">
        <ChartPanel configuration={graphAnomalyperWeek()} />
      </div>

      <div className="row">
        <AnomalyTable rows={anomalyRows} getMore={() => { findAnomalyperWeek() }} loading={tableLoading} />
      </div>

      <div className="row">
        <ChartPanel configuration={GraphConfigurationBuilder(anomalyGraphicConfiguration, anomalyData, dateAnomalyData)} />
        <p>Quantidade de eventos de anomalia: {numberOfAnomalyEvents}</p>
        <p>Porcentagem de eventos de anomalia: {percentOfAnomalyEvents}</p>
      </div>


      <div className="row">
        <ChartPanel configuration={GraphConfigurationBuilder(wifiGraphicConfiguration, wifiQuality, dateWifiQuality)} />
        <ChartPanel configuration={GraphConfigurationBuilder(batGraphicConfiguration, batteryData, dateBatteryData)} />
      </div>

      <div className="row">
        <ChartPanel configuration={GraphConfigurationBuilder(gryGraphicConfiguration, gyroscopeData, dateGyroscopeData)} />
        <ChartPanel configuration={GraphConfigurationBuilder(accGraphicConfiguration, accelerometerData, dateAccelerometerData)} />
      </div>

      <div className="row">
        <ChartPanel configuration={GraphConfigurationBuilder(temperatureGraphicConfiguration, temperatures, dateTemperatures)} />
        <ChartPanel configuration={GraphConfigurationBuilder(noiseGraphicConfiguration, noiseLevel, dateNoiseLevel)} />
      </div>

      <div className="row">
        <ChartPanel configuration={GraphConfigurationBuilder(timeOperationGraphicConfiguration, operationData, dateOperation)} />
        <p>Tempo em operação: {timeOfOperation} segundos</p>
      </div>
    </div>
  );
}
