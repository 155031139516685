import React from "react";
import PropTypes  from "prop-types";

export default function PeriodSelector({selectPeriodOfSearch}){
    function select(periodSelectd){
        selectPeriodOfSearch(periodSelectd)
    }

    return (
              <div className="row">
                  <div className="col">
                  </div>
                  <div className="col-6">
                  </div>
                  <div className="col">
                    Periodo: &ensp;

                    <select name="period" id="period" onChange={e => select(e.target.value)}>
                      <option value="ONE_HOUR">1 dia</option>
                      <option value="TWO_HOURS">2 dias</option>
                      <option value="ONE_WEEK">1 semana</option>
                      <option value="TWO_WEEKS">2 semanas</option>
                      <option value="ONE_MONTH">1 mês</option>
                      <option value="CUSTOM">Personalizar</option>
                    </select>
                  </div>
              </div>
    )
}

PeriodSelector.propTypes = {
  selectPeriodOfSearch: PropTypes.func.isRequired   
}