import React from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from "./pages/Graphs/graphs";
import { ToastContainer } from "react-toast";

export default function Rotas() {
    return(
    <BrowserRouter>
        <ToastContainer autoclose={300} />
        <Routes>
            <Route default path='/:idDevice' element={<Home />} />
        </Routes>
    </BrowserRouter>
    )
}