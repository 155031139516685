import React from "react";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

export default function ChartPanel({configuration}){
    
    return (
        <div className="col-sm">
           <HighchartsReact highcharts={Highcharts}
                            options={configuration}/> 
        </div>
    )
}
