import React from "react";

import './top.scss';

export default function Top({titlePage}){
    return (
            <div className='graph-main-content-title d-flex justify-content-center'>
                {titlePage}
            </div>
    )
}