import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import CircularProgress from '@mui/material/CircularProgress';




export default function AnomalyTable({ rows, getMore, loading }) {
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Data Inicial</TableCell>
                        <TableCell align="right">Data Final</TableCell>
                        <TableCell align="right">Número de anomalias</TableCell>
                        <TableCell align="right">Porcentagem de anomalias</TableCell>
                        <TableCell align="right">Tempo de operação</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow
                            key={row.id}
                        >
                            <TableCell component="th" scope="row">
                                {row.dateI}
                            </TableCell>
                            <TableCell align="right">{row.dateF}</TableCell>
                            <TableCell align="right">{row.anomaly}</TableCell>
                            <TableCell align="right">{row.percent  + '%'}</TableCell>
                            <TableCell align="right">{row.operating}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>

            </Table>
                <Stack sx={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={4}>
                {loading && <CircularProgress />}
                <Button sx={{ margin: '30px' }} variant="contained" onClick={() => { getMore() }}>Semanas mais antigas</Button>
            </Stack>
        </TableContainer>
    );
}
